import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { tableNames } from 'constants';
import { getData } from 'firebaseConfig';
import PalDetailsComponent from '../details';

const PalCards = () => {
  const { dbUser: currentUser } = useSelector(state => state.authUser);
  const [pals, setPals] = useState([]);

  useEffect(() => {
    const fetchPals = async () => {
      const res = await getData(tableNames.USER);
      setPals(res);  
    };

    fetchPals();
  }, []);

  return (
    <>
    <div className="container my-5 pt-5">
      <h4 className='text-center'>Embark on a Pal-tastic Journey</h4>
      <div className='text-center'>Connect with an eclectic mix of pals, ready to share unforgettable experiences with you.</div>

      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 mt-3 align-items-center">
        {pals.map((pal, index) => (pal.id !== currentUser?.id) && (
          <div key={index} className="col">
            <PalDetailsComponent pal={pal} currentUser={currentUser} />
          </div>
        ))}
      </div>
    </div>
    </>
  );
};

export default PalCards;
