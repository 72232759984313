import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaEnvelope, FaTimes } from 'react-icons/fa';

import { routes } from 'constants';
import { signout } from 'firebaseConfig';
import { favicon } from 'constants';

function AuthHeaderComponent(props) {
  const navigate = useNavigate();
  const {dbUser: currentUser} = useSelector(state => state.authUser);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const signoutUser = async () => {
    await signout();
    sessionStorage.clear();
    navigate(routes.HOME);
    window.location.reload();
  }

  return (
    <nav className="navbar navbar-expand-lg bg-white fixed-top navbar-custom">
      <div className="container">
        {/* Brand */}
        <Link className="navbar-brand" to={routes.VIEW_PAIRUPS}>PairupPal</Link>
        
        {/* Navbar toggler for small screens */}
        <button className="navbar-toggler no-border" type="button" onClick={e => setIsNavbarOpen(!isNavbarOpen)}>
          {isNavbarOpen ? <FaTimes /> : <span className="navbar-toggler-icon"></span>}
        </button>
        
        {/* Navbar items */}
        <div className={`collapse navbar-collapse justify-content-between ${isNavbarOpen ? 'show' : 'hide'}`} id="navbarNav">
          <ul className="navbar-nav" onClick={e => setIsNavbarOpen(false)}>
            <li className="nav-item px-3 py-2">
              <Link className="nav-link" to={routes.VIEW_PAIRUPS}>Pairups</Link>
            </li>
            <li className="nav-item px-3 py-2">
              <Link className="nav-link" to={routes.VIEW_PALS}>Pals</Link>
            </li>
            <li className="nav-item px-3 py-2">
              <Link className="nav-link" to={routes.CHATS}>Chats</Link>
            </li>
          </ul>
          
          {/* Login button */}
          <ul className="navbar-nav ms-auto">
            <li className="nav-item px-2 py-2 d-none">
              <Link className="nav-link" to={routes.CHATS}><FaEnvelope /></Link>
            </li>
            <li className="nav-item px-3 py-2 dropdown">
              <Link className="nav-link dropdown-toggle" to={routes.HUSTLER_GROUP} id="userMenu" data-bs-toggle="dropdown" aria-expanded="false">
                <img src={currentUser?.photo || favicon.url} className='pal-photo' alt={currentUser?.name || favicon.name} />  &nbsp;
                Hello {currentUser?.name}
              </Link>
              <ul className="dropdown-menu animated fadeIn shadow" aria-labelledby="userMenu" onClick={e => setIsNavbarOpen(false)}>
                <li><Link className="dropdown-item" to={routes.CREATE_PAIRUP}>Create Pairup</Link></li>
                <li><hr className="dropdown-divider" /></li>
                <li><Link className="dropdown-item" to={routes.VIEW_USER_PAIRUPS}>Your Pairups</Link></li>
                <li><Link className="dropdown-item" to={routes.SETTINGS}>Settings</Link></li>
                <li><hr className="dropdown-divider" /></li>
                <li><div className="dropdown-item pointer" onClick={signoutUser}>Logout</div></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default AuthHeaderComponent;
