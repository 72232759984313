import React, { useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';

import { tableNames, routes } from 'constants';
import { getIdData } from 'firebaseConfig';
import { Link, useParams } from 'react-router-dom';
import PairupDetailsComponent from '../details';

const SingleView = () => {
  const { pairUpId } = useParams();
  const [pairUp, setPairUp] = useState([]);

  useEffect(() => {
    const fetchPairUp = async () => {
      const pairupRes = await getIdData(tableNames.PAIRUP, pairUpId);
      setPairUp({
        ...pairupRes,
        id: pairUpId
      });
    };

    fetchPairUp();
  }, [pairUpId]);

  return (
    <>
    <div className="container my-5 pt-5">
      <div className="row justify-content-center mt-3">
        {pairUp && pairUp.user ? (
          <div className="col-sm-12 col-md-6">
            <div className="">
                <Link className='btn btn-link text-decoration-none' to={routes.VIEW_PAIRUPS}>
                  <FaArrowLeft /> &nbsp;
                  Go back
                </Link>
                <PairupDetailsComponent pairup={pairUp} showFullDetails={true} />
            </div>
        </div>
        ) : (
          <div className='text-center'>
            <h3>Pairup is no longer available.</h3>
            <Link className='btn btn-link text-decoration-none' to={routes.VIEW_PALS}>
              <FaArrowLeft /> &nbsp;
              Go back
            </Link>
          </div>        
        )}
      </div>
    </div>
    </>
  );
};

export default SingleView;
