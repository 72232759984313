export const pricingOptions = [
    {
        title: "Free for Life",
        price: "£0 per month / £0 per year",
        description: "Checkout our PairUpPal platform and look all available pairups.",
        discount: "Always available",
        features: [
          "Join upto 5 pairups",
          "Chat with paired up pals only",
          "Get zero discount on all pairup events",
          "Access to our WhatsApp channel",
          "No customer support"
        ],
        buttonText: "Get Started"
    },
    {
      title: "Basic Pals",
      price: "£5 per month / £50 per year",
      description: "Enjoy our PairUpPal membership and unlock limited access to our platform.",
      discount: "Minimum 6-month membership is required",
      features: [
        "Join upto 25 pairups",
        "Chat with paired up pals only",
        "Get 10% discount on all pairup events",
        "Access to our WhatsApp channel & community",
        "Access to all new features",
        "Mon-Thu customer support"
      ],
      buttonText: "Get Started"
    },
    {
      title: "Premium Pals",
      price: "£25 per month / £200 per year",
      description: "Become one of our elite PairUp Pal and unlock full access to our platform.",
      note: "Limited spots available. Minimum 4-month membership is required",
      features: [
        "Join or Ask unlimited pairups",
        "Access to special pairups*",
        "Chat with everyone",
        "Advanced AI filters available",
        "Get 50% discount on all pairup events",
        "Access to our private WhatsApp groups",
        "Early access to all new features",
        "24x7 priority customer support",
      ],
      buttonText: "Join Now"
    }
  ];