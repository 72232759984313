export const pairupTypes = [
  {
    key: "public",
    value: "Free: Anyone can join",
    isPaid: false,
    display: true, // display type
    disabled: false // disable create button
  },
  {
    key: "private",
    value: "Free: Request to join (Coming Soon)",
    isPaid: false,
    display: true,
    disabled: true
  },
  {
    key: "one_to_one",
    value: "Free: One-to-one (Coming Soon)",
    isPaid: false,
    display: false,
    disabled: true
  },
  {
    key: "paid_public",
    value: "Paid: Anyone can join but pay upfront (Coming Soon)",
    isPaid: true,
    display: true,
    disabled: true
  },
  {
    key: "paid_one_to_one",
    value: "Paid: One-to-one (Coming Soon)",
    isPaid: true,
    display: false,
    disabled: true
  },
  {
    key: "paid_private",
    value: "Request to join but then paid",
    isPaid: true,
    display: false,
    disabled: true
  }
];