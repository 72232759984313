import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { where } from 'firebase/firestore';

import { addData, getIdData, getQuery, updateData } from 'firebaseConfig';
import { tableNames } from "constants";
import ChatSidebarComponent from './chatSidebar';
import ChatMessagesComponent from './chatMessages';
import { getScreenSize } from 'helpers';


const ChatsComponent = () => {
  const { dbUser: currentUser } = useSelector(state => state.authUser);
  const { state: routeData } = useLocation();
  const [chats, setChats] = useState();
  const [selectedChat, setSelectedChat] = useState(routeData?.selectedChat || '');
  const [chatMessages, setChatMessages] = useState();
  const [newChatMessage, setNewChatMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [stepNumber, setStepNumber] = useState(1);
  const screenSize = getScreenSize();

  useEffect(() => {
    async function loadChats() {
      // retrieve chats based on userId
      let chatsRes = await getQuery(
        tableNames.CHAT, where("userIds", "array-contains", currentUser.id)
      );
      chatsRes = await Promise.all(chatsRes.map(async (cx, idx) => {
        const otherUserId = cx.userIds.find(cxId => cxId !== currentUser.id);
        const otherUserData = await getIdData(tableNames.USER, otherUserId);

        return {
          ...cx,
          otherUserData: {
            id: otherUserId,
            ...otherUserData
          }
        }
      }));
      setChats(chatsRes);
    }
    loadChats();
  }, [currentUser]);

  const loadChatMessages = async (chat) => {
    // retrieve chatMessages based on userId
    const res = await getQuery(
      tableNames.CHAT_MESSAGE, where("chatId", "==", chat.id)
    );
    setChatMessages(res);
    setSelectedChat(chat);
    setStepNumber(2);
  }

  const sendMessage = async () => {
    setLoading(true);
    const newMessage = {
      chatId: selectedChat.id,
      senderId: currentUser.id,
      receiverId: selectedChat.otherUserData?.id,
      content: newChatMessage,
      type: "text",
      contentLink: ''
    };
    const newMessageRes = await addData(tableNames.CHAT_MESSAGE, newMessage);

    if (newMessageRes) {
      setChatMessages([
        ...chatMessages, 
        newMessage
      ]);
      setNewChatMessage("");
      await updateData(tableNames.CHAT, selectedChat.id, {
        lastMessage: newMessage.content
      });
    }
    setLoading(false);
  }

  return (
    <div className="container container-body mt-md-5 pt-5">
      {
        screenSize <= 2 ? (
          <>
            {stepNumber === 1 && <ChatSidebarComponent chats={chats} loadChatMessages={loadChatMessages} />}
            {stepNumber === 2 && <ChatMessagesComponent selectedChat={selectedChat} chatMessages={chatMessages} newChatMessage={newChatMessage} setNewChatMessage={setNewChatMessage} sendMessage={sendMessage} screenSize={screenSize} setStepNumber={setStepNumber} loading={loading} />}
          </>
        ) : (
          <div className='d-flex flex-row'>
            <ChatSidebarComponent chats={chats} loadChatMessages={loadChatMessages} />
            <ChatMessagesComponent selectedChat={selectedChat} chatMessages={chatMessages} newChatMessage={newChatMessage} setNewChatMessage={setNewChatMessage} sendMessage={sendMessage} loading={loading} />
          </div>  
        )
      }
    </div>
  );
};

export default ChatsComponent;