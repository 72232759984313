import React from 'react';

import { tableNames, routes } from 'constants';
import { addData, getQuery } from 'firebaseConfig';
import { Link, useNavigate } from 'react-router-dom';
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin, FaTwitterSquare } from 'react-icons/fa';
import { where } from 'firebase/firestore';
import { favicon } from 'constants';
import { toast, trimString } from 'helpers';

const PalDetailsComponent = ({
  pal,
  showFullDetails,
  currentUser
}) => {
  const navigate = useNavigate();

  const askForPairup = async () => {
    /* If not logged in, redirect to login page or alert
     1. If not logged in, redirect to log in screen
     2. Find whether a chat already exists
     3. If not, create new chat
     4. Then, send a message
    */
     if (!currentUser) {
      navigate(routes.LOGIN);
      return;
    }
    if (!pal) {
      toast.error("Something not right. Please refresh your screen");
      return;
    }

    // retrieve chats based on userId
    const currentUserChats = await getQuery(tableNames.CHAT, where("userIds", "array-contains", currentUser?.id));
    const isChatExists = currentUserChats.find(cuc => cuc.userIds.find(cucId => cucId === pal?.id));

    if (!isChatExists) {
      await addData(tableNames.CHAT, {
        userIds: [currentUser?.id, pal.id]
      });
    }
    navigate(routes.CHATS, {
      state: {
        selectedChat: isChatExists
      }
    });
  }

  return (
    <>
      <div className="card pairup-card">
        <div className="card-body h-100">
          <div className='d-flex align-items-center'>
            <img src={pal.photo || favicon.url} className="image-fluid pairup-photo" alt={pal.name} />
            <Link className='text-normal mx-1' to={`${routes.VIEW_PALS}/${pal.id}`}>
              <span className="h4 text-capitalize mx-2">{pal.name}</span>
              <span className="pl-2">({pal.pronouns})</span>
            </Link>
          </div>
          <div className="text-capitalize mt-2 mb-1">{(!pal.about || showFullDetails) ? pal.about : trimString(pal.about, 60)}</div>
          {pal.linkedin && (
            <a className="my-1" target='_blank' rel="noopener noreferrer" href={pal.linkedin}>
              <FaLinkedin />
            </a>
          )}
          {pal.twitter && (
            <a className="my-1" target='_blank' rel="noopener noreferrer" href={pal.twitter}>
              <FaTwitterSquare />
            </a>
          )}
          {pal.instagram && (
            <a className="my-1" target='_blank' rel="noopener noreferrer" href={pal.instagram}>
              <FaInstagramSquare />
            </a>
          )}
          {pal.facebook && (
            <a className="my-1" target='_blank' rel="noopener noreferrer" href={pal.facebook}>
              <FaFacebookSquare />
            </a>
          )}
          
        </div>
        {currentUser?.id !== pal.id &&
          <div className="card-footer bg-transparent text-primary fs-14 pointer" onClick={askForPairup}>Ask for Pairup</div>
        }
      </div>

    </>
  );
};

export default PalDetailsComponent;
