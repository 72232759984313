import { Link } from 'react-router-dom';
import { routes } from 'constants';
import { FaArrowDown } from 'react-icons/fa';

import WhyUseSection from './whyUseComponent';
import WhyUniqueSection from './whyUniqueComponent';

function HomeComponent() {
  return (
    <div className='mt-4 pt-5'>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img src="https://t4.ftcdn.net/jpg/05/55/89/53/360_F_555895347_Ef8KZBnAucLAD0BL7lOdd9EbUroSl52Y.jpg" className="App-logo d-none" alt="logo" />
        <section className='welcome-section'>
          <div className='welcome-overlay'></div>
          <div className='container-fluid text-center welcome-content'>
            <h2 className='h1 pt-5 mt-5'>Explore Exciting Pair-Ups for Hobbies, Activities, and More</h2>
            <p className='fs-16'>
            A friendly and collaborative space for individuals to socialize, exercise, and pursue shared interests, fostering connections and combating loneliness.
            </p>
            <Link
              className="btn btn-turquoise fw-500 px-4 py-2 mt-3"
              to={routes.SIGN_UP}
            >
              Scroll down for more.. <FaArrowDown />
            </Link>
          </div>
        </section>
        <WhyUseSection />
        <Link
          className="btn btn-turquoise fw-500 px-4 mt-5"
          to={routes.SIGN_UP}
        >
          Not Yet Convinced!? Check below..
        </Link>
        <WhyUniqueSection />
        <Link
          className="btn btn-turquoise fw-500 px-3 mb-5"
          to={routes.SIGN_UP}
        >
          Thanks for your time!
        </Link>
      </div>
    </div>
  );
}

export default HomeComponent;