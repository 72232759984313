import { SET_DB_USER, SET_LOGGED_IN, SET_RELOAD, SET_USER } from "store/types"

export const SetUser = (content) => {
    return {
        type: SET_USER,
        payload: {
            user: content
        }
    }
}

export const SetDbUser = (content) => {
    return {
        type: SET_DB_USER,
        payload: {
            dbUser: content
        }
    }
}

export const SetLoggedIn = (content) => {
    return {
      type: SET_LOGGED_IN,
      payload: {
        loggedIn: content,
      },
    };
  };
  
  export const SetReload = (content) => {
    return {
      type: SET_RELOAD,
      payload: {
        reload: content,
      },
    };
  };