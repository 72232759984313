import React from 'react';
import { Outlet } from 'react-router-dom';
import { StaticHeader, FooterComponent } from 'components';

const DefaultLayout = ({ children }) => {
  return (
    <div>
      <StaticHeader />
      <main>
        <Outlet />
      </main>
      <FooterComponent />
    </div>
  );
};

export default DefaultLayout;
