import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const normal = (msg) => toast(msg);

export const warn = (msg) => toast.warning(msg);

export const success = (msg) => toast.success(msg);

export const error = (msg) => toast.error(msg);

export const container = () => <ToastContainer/>;