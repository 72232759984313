import React from 'react';
import { favicon } from 'constants';

const ChatSidebarComponent = ({
  chats, loadChatMessages
}) => {
  return (
    <div className="container container-body mt-0 pt-5 col-12 col-md-3">
      <div className='leftside card pairup-card h-70'>
        {
          chats && chats[0] && chats.map(chat => (
            <div key={chat.id} className='d-flex align-items-center pointer my-2' onClick={e => loadChatMessages(chat)}>
              <img src={chat.otherUserData?.photo || favicon.url} className="image-fluid pairup-photo" alt={favicon.name} />
              <div className='text-normal mx-1'>
                <span className="pl-2">{chat.otherUserData?.name}</span>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default ChatSidebarComponent;