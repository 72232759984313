export const getScreenSize = () => {
  const screenWidth = window.innerWidth;

  const breakpoints = {
    xs: 0,    // Extra small devices (portrait phones, less than 576px)
    sm: 576,  // Small devices (landscape phones, 576px and up)
    md: 768,  // Medium devices (tablets, 768px and up)
    lg: 992,  // Large devices (desktops, 992px and up)
    xl: 1200  // Extra large devices (large desktops, 1200px and up)
  };

  // Check which breakpoint the screen size falls into
  if (screenWidth < breakpoints.sm) {
    return 1; // "xs"
  } else if (screenWidth < breakpoints.md) {
    return 2; // "sm"
  } else if (screenWidth < breakpoints.lg) {
    return 3; // "md"
  } else if (screenWidth < breakpoints.xl) {
    return 4; // "lg"
  } else {
    return 5; // "xl"
  }
}