import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { where } from 'firebase/firestore';

import { tableNames } from 'constants';
import { toast } from 'helpers';
import { getQuery, removeData } from 'firebaseConfig';
import PairupDetailsComponent from 'components/pairups/details';

const UserPairUps = () => {
  const { dbUser: currentUser } = useSelector(state => state.authUser);
  const [pairUps, setPairUps] = useState([]);

  useEffect(() => {
    const fetchPairUps = async () => {
        const res = await getQuery(tableNames.PAIRUP, where("user.id", "==", currentUser.id));
        setPairUps(res);  
    };

    fetchPairUps();
  }, [currentUser]);

  const removePairup = async (e) => {
    if (window.confirm(`Are you sure you want to delete this PairUp for ${e.name}?`)) {
        const res = await removeData(tableNames.PAIRUP, e.id)
        if (res.status === 200) {
            toast.success('Your pairup is removed.');
            const updatedPairUps = pairUps.filter(pairUp => pairUp.id !== e.id);
            setPairUps(updatedPairUps);
        } else {
            toast.error('Something went wrong. Try again later!')
        }
    }
  }

  return (
    <>
    <div className="container mt-5 pt-5">
      <h4 className='text-center'>Your Pariups</h4>
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 mt-3">
        {pairUps.map((pairUp, index) => (
          <div key={index} className="col">
            <PairupDetailsComponent pairup={pairUp} />
            {
              !pairUp.participantIds?.length && (
                <div className="card-footer bg-transparent text-danger text-center pointer fs-14" onClick={e => removePairup(pairUp)}>
                  Delete Pairup
                </div>  
              )
            }
          </div>
        ))}
      </div>
    </div>
    </>
  );
};

export default UserPairUps;
