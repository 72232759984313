import React from 'react';
import { useSelector } from 'react-redux';
import { FaMapMarkerAlt, FaCalendarAlt, FaClock, FaUser, FaPoundSign, FaUsers } from 'react-icons/fa';

import { tableNames, routes } from 'constants';
import { compareTime, formatDateTime, toast, trimString } from 'helpers';
import { updateData } from 'firebaseConfig';
import { calculateTimeDifference } from 'helpers';
import { Link, useNavigate } from 'react-router-dom';

const PairupDetailsComponent = ({
  pairup,
  showFullDetails
}) => {
  const { dbUser: currentUser } = useSelector(state => state.authUser);
  const navigate = useNavigate();
  const currentTime = new Date();

  const isAdmin = currentUser?.id !== pairup.user?.id;
  const isParticipant = pairup.participantIds?.find(pid => pid === currentUser?.id);
  const isRequestedParticipant = pairup.requestedParticipantIds?.find(pid => pid === currentUser?.id);

  const requestToAttend = async () => {
    if (!currentUser) {
      navigate(routes.LOGIN);
      return;
    }
    if (!pairup) {
      toast.error("Something not right. Please refresh your screen");
      return;
    }
    // If not logged in, redirect to login page or alert
    // If logged in, 
    // 1. save the record in MESSAGE table
    // 2. create new alert in ALERT table
    let participantIds = [];
    let requestedParticipantIds = [];

    if (pairup.type?.key === "public") {
      participantIds = [
        ...pairup.participantIds,
        currentUser.id
      ];
    } else if (pairup.type?.key === "private") {
      requestedParticipantIds = [
        ...pairup.requestedParticipantIds || [],
        currentUser.id
      ];
    }

    const pairupRes = await updateData(tableNames.PAIRUP, pairup.id, {
      participantIds: [...new Set(participantIds)].flat(),
      requestedParticipantIds: [...new Set(requestedParticipantIds)].flat()
    });
    if (pairupRes) {
      toast.success("Your attendace is sent 🙌");
      navigate(routes.VIEW_PAIRUPS + "/" + pairup.id);
    }
  }

  return (
    <>
      <div className="card pairup-card">
        <div className="card-body h-100">
          <Link className='text-normal' to={`${routes.VIEW_PAIRUPS}/${pairup.id}`}>
            <div className='text-orange font-opensans fw-600'>PAIRUP</div>
            <div className="fs-18 text-capitalize">{pairup.name}</div>
            <p className="fs-14">{showFullDetails ? pairup.description : trimString(pairup.description, 60)}</p>
          </Link>
            <p className="text-secondary my-1">
              <FaUser /> &nbsp;
              <Link to={`${routes.VIEW_PALS}/${pairup.user.id}`} className="text-capitalize no-decoration">{pairup.user.name}</Link>
              <span className="mx-2 d-none">({pairup.user.pronouns})</span>
            </p>
            {
              pairup.type?.isPaid && (
                <div className="text-secondary my-1 text-capitalize d-flex flex-row align-items-center">
                  <FaPoundSign />
                  <div className='px-2'>{pairup.cost}</div>
                </div>  
              )
            }
            <div className="text-secondary my-1 text-capitalize d-flex flex-row align-items-center">
              <FaCalendarAlt /> &nbsp;
              <div className='px-2'>{formatDateTime(pairup.startTime)}</div>
            </div>
            {
              pairup.attendance && (
                <div className="text-secondary my-1 text-capitalize d-flex flex-row align-items-center">
                  <FaUsers />&nbsp;
                  <div className='px-2'>{pairup.attendance}</div>
                </div>  
              )
            }
            <div className="text-secondary my-1 text-capitalize d-flex flex-row align-items-center">
              <FaClock />&nbsp;
              <div className='px-2'>{calculateTimeDifference(pairup.startTime, pairup.endTime)}</div>
            </div>
            <div className="text-secondary my-1 text-capitalize d-flex flex-row align-items-center">
              <FaMapMarkerAlt className='pairup-icon' />  &nbsp;
              <div className='px-2'>
                {pairup.address}, <span className='text-uppercase'>{pairup.zipcode}</span>
              </div>
            </div>
        </div>
        {
          isParticipant && (
            <div className="card-footer bg-transparent text-black fs-14" onClick={requestToAttend}>
              Joined
            </div>
          )
        }
        {
          isRequestedParticipant && (
            <div className="card-footer bg-transparent text-black fs-14" onClick={requestToAttend}>
              Requested
            </div>
          )
        }
        {compareTime(pairup.startTime, "gt", currentTime) ? 
          isAdmin && !isParticipant && !isRequestedParticipant &&
          <div className="card-footer bg-transparent text-primary fs-14 pointer" onClick={requestToAttend}>
            {(pairup.type?.key === "public" || !pairup.type) && <span>Join Pairup</span>}
            {pairup.type?.key === "private" && <span>Request to join</span>}
            {pairup.type?.key === "paid_public" && <span>Pay Now</span>}
            {pairup.type?.key === "paid_private" && <span>Pay Now</span>}
          </div>
          : 
          <div className="card-footer bg-transparent text-secondary fs-14">Completed</div>
        }
      </div>
    </>
  );
};

export default PairupDetailsComponent;
