import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { favicon } from 'constants';
import { FaArrowLeft } from 'react-icons/fa';

const ChatMessagesComponent = ({
  selectedChat,
  chatMessages,
  newChatMessage,
  setNewChatMessage,
  sendMessage,
  screenSize,
  setStepNumber,
  loading
}) => {
  const { dbUser: currentUser } = useSelector(state => state.authUser);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  return (
    <div className="container container-body pt-5 col-12 col-md-9">
      <div className='d-flex flex-column card pairup-card'>
        <div className='px-1 py-2'>
          {selectedChat ? (
            <>
              <div className='chat-header'>
                <div className='d-flex align-items-center pointer my-2'>
                  {screenSize <= 2 && <FaArrowLeft onClick={e => setStepNumber(1)} />} &nbsp;
                  <img src={selectedChat.otherUserData?.photo || favicon.url} className="image-fluid pal-photo" alt={favicon.name} />
                  <div className='fw-700 mx-1'>
                    <span className="pl-2">{selectedChat.otherUserData?.name}</span>
                  </div>
                </div>
              </div>
              <div className='chat-messages px-3 h-40' ref={chatContainerRef}>
                {
                  chatMessages && chatMessages.map(msg => (
                    <div key={msg.id} className='d-flex align-items-center msg'>
                      <div className={`message m-1 ${(currentUser.id === msg.senderId) ? "text-right float-end ms-auto" : "text-left float-start me-auto"}`}>
                        <div className="pl-2">{msg.content}</div>
                      </div>
                    </div>  
                  ))
                }
              </div>
              <div className='new-message m-2'>
                <textarea className="form-control my-2 fs-14" value={newChatMessage} onChange={e => setNewChatMessage(e.target.value)} required placeholder='Send your message..' />
                <button className="btn btn-primary btn-sm col-4 col-sm-3 col-md-2" onClick={sendMessage} disabled={!newChatMessage || loading}>Send</button>
              </div>
            </>
          ): (
            <div className='text-center'>
              No chat selected. Start Chatting now..
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatMessagesComponent;