import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { toast } from 'helpers';
import { tableNames, routes } from 'constants';
import { addData } from 'firebaseConfig';
import { useSelector } from 'react-redux';

const pairupTypes = [
  {
    key: "public",
    value: "Free: Anyone can join",
    display: true
  },
  {
    key: "private",
    value: "Free: Request to join",
    display: true
  },
  {
    key: "paid_public",
    value: "Paid: Anyone can join but pay upfront (Coming Soon)",
    display: true
  },
  {
    key: "paid_private",
    value: "Request to join but then paid"
  },
  {
    key: "paid_private",
    value: "Request to join but then paid"
  }
];

function CreatePairupsComponent() {
  const { dbUser: currentUser } = useSelector(state => state.authUser);
  const { palId } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    address: '',
    zipcode: '',
    startTime: '',
    endTime: '',
    cost: '',
    type: pairupTypes[0],
    attendance: 1,
  });
  const [selectedOption, setSelectedOption] = useState(pairupTypes[0]);

  useEffect(() => {
    async function getUserDetails() {
      
    }
    getUserDetails();
  }, [palId]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    const selectedObject = pairupTypes.find(option => option.key === selectedValue);
    console.log("---", selectedValue, selectedObject);
    setSelectedOption(selectedObject);
  };

  const handleSubmit = async (e) => {
    // Perform form validation
    if (formData.name && formData.address && formData.zipcode && formData.startTime && formData.endTime) {
      const res = await addData(tableNames.PAIRUP, {
        ...formData,
        user: {
          id: currentUser.id,
          name: currentUser.name,
          pronouns: currentUser.pronouns,
          linkedin: currentUser.linkedin,
          photo: currentUser.photo
        }
      });
      if (res) {
        toast.success('Your pairup request is live!');
        navigate(routes.VIEW_PAIRUPS);
      } else {
        toast.error('Something went wrong. Try again later!')
      }
    } else {
      toast.warn('Please fill in all fields');
    }
  };

  return (
    <>
      <div className='container pt-5 mt-5'>
        <h4 className='text-center'>Create a pairup request & spend your hobby time with a Pal to share ideas.</h4>
        <div className='container max-w-70'>
          <div className='row'>
            <div className="mb-3 col-12 col-sm-6">
                <label htmlFor="type" className="form-label">Pairup Type</label>
                <select className="form-select" id="type" name="type" onChange={handleSelectChange} required>
                  {pairupTypes.map(put => put.display && <option value={put.key}>{put.value}</option>)}
                </select>
            </div>
            {
              (selectedOption?.key === "paid_public" || selectedOption?.key === "paid_private") && (
                <div className="mb-3 col-12 col-sm-6">
                  <label htmlFor="cost" className="form-label">Costs involved</label>
                  <input type="text" className="form-control" id="cost" name="cost" value={formData.cost} onChange={handleChange} required placeholder='appx. £100 per head' />
                </div>
              )
            }
            <div className='divider'></div>
            <div className="mb-3 col-12 col-sm-6">
                <label htmlFor="name" className="form-label">Pairup For</label>
                <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} required placeholder='Hiking, swimming, walking, dancing, etc.' />
            </div>
            <div className="mb-3 col-12 col-sm-6">
                <label htmlFor="description" className="form-label">Description</label>
                <textarea className="form-control" id="description" name="description" value={formData.description} onChange={handleChange} placeholder='Tell more about this pairup..' />
            </div>
            <div className="mb-3 col-12 col-sm-6">
                <label htmlFor="startTime" className="form-label">Start time</label>
                <input type="datetime-local" className="form-control" id="startTime" name="startTime" value={formData.startTime} onChange={handleChange} required placeholder="Date" />
            </div>
            <div className="mb-3 col-12 col-sm-6">
                <label htmlFor="endTime" className="form-label">End time</label>
                <input type="datetime-local" className="form-control" id="endTime" name="endTime" value={formData.endTime} onChange={handleChange} required />
            </div>
            <div className="mb-3">
                <label htmlFor="attendance" className="form-label">No. of people can attend</label>
                <input type="number" className="form-control" id="attendance" name="attendance" value={formData.attendance} onChange={handleChange} required />
            </div>
            <div className="mb-3 col-12 col-sm-6">
                <label htmlFor="zipcode" className="form-label">Zipcode</label>
                <input type="text" className="form-control" id="zipcode" name="zipcode" value={formData.zipcode} onChange={handleChange} required />
            </div>
            <div className="mb-3 col-12 col-sm-6">
                <label htmlFor="address" className="form-label">Address</label>
                <input type='text' className="form-control" id="address" name="address" value={formData.adress} onChange={handleChange} required placeholder='Complete Address...' />
            </div>
            <button className="btn btn-primary col-sm-4" onClick={handleSubmit} disabled={selectedOption.key === "paid_public" || selectedOption.key === "paid_private"}>Create Pairup</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreatePairupsComponent;
