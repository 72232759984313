import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MultiSelect } from 'react-multi-select-component';

import { FileUploadComponent } from 'components';
import { toast } from 'helpers';
import { ukCities as cities, sports, tableNames, routes } from 'constants';
import { updateData } from 'firebaseConfig';
import { useSelector } from 'react-redux';

function UserProfileComponent() {
  const { dbUser: currentUser } = useSelector(state => state.authUser);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: currentUser.name || '',
    email: currentUser.email,
    pronouns: currentUser.pronouns || '',
    dob: currentUser.dob,
    location: currentUser.location || '',
    about: currentUser.about || '',
    linkedin: currentUser.linkedin || '',
    instagram: currentUser.instagram || '',
    twitter: currentUser.twitter || '',
    facebook: currentUser.facebook || '',
  });
  const [selectedSports, setSelectedSports] = useState([]);
  const [fileUrl, setFileUrl] = useState(currentUser.photo || '');
  const today = new Date();
  const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
  const formattedDate = eighteenYearsAgo.toISOString().split('T')[0];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    // Perform form validation
    if (formData.name && formData.email && formData.pronouns && formData.dob && formData.about) {
      // Form is valid, save to Firestore
      const res = await updateData(tableNames.USER, currentUser.id, {
        ...formData,
        sports: selectedSports,
        photo: fileUrl
      });
      if (res) {
        toast.success('Your profile is updated successfully ✔️');
        navigate(routes.SETTINGS);
      } else {
        toast.error('Something went wrong. Try again later!')
      }
    } else {
      toast.warn('Please fill in all fields');
    }
  };

  return (
    <>
        <div className='d-flex flex-column justify-content-center align-items-center'>
            <div className='text-black'>
            <div className="mb-3">
                <label htmlFor="name" className="form-label">Name</label>
                <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} required />
            </div>
            <div className="mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input type="email" className="form-control" id="email" name="email" value={formData.email} disabled />
            </div>
            <div className="mb-3">
                <label htmlFor="pronouns" className="form-label">Pronouns</label>
                <select className="form-select" id="pronouns" name="pronouns" value={formData.pronouns} onChange={handleChange} required>
                <option value="">Select Pronouns</option>
                <option value="he/him">He/Him</option>
                <option value="she/her">She/Her</option>
                <option value="other">Other</option>
                </select>
            </div>
            <div className="mb-3">
              <label htmlFor="dob" className="form-label">Date of Birth *</label>
              <input type="date" className="form-control" id="dob" name="dob" value={formData.dob} onChange={handleChange} required minDate={formattedDate} />
            </div>
            <div className="mb-3 d-none">
                <label htmlFor="age" className="form-label">Age</label>
                <select className="form-select" id="age" name="age" value={formData.age} onChange={handleChange} required>
                <option value="">Select Age</option>
                {Array.from({ length: 82 }, (_, i) => i + 18).map(age => (
                    <option key={age} value={age}>{age}</option>
                ))}
                </select>
            </div>
            <div className="mb-3">
                <label htmlFor="about" className="form-label">About yourself</label>
                <textarea className="form-control" id="about" rows="3" name="about" value={formData.about} onChange={handleChange} required></textarea>
            </div>
            <div className="mb-3 d-none">
                <label htmlFor="location" className="form-label">Location to find Pals</label>
                <select className="form-select" id="location" name="location" value={formData.location} onChange={handleChange} required>
                <option value="">Select Location</option>
                {/* Assuming you have a list of UK cities */}
                {cities.map(city => (
                    <option key={city} value={city}>{city}</option>
                ))}
                </select>
            </div>
            {/* <pre>{selectedSports && JSON.stringify(selectedSports)}</pre> */}
            <div className="mb-3 d-none">
                <label htmlFor="sports" className="form-label">Sport Interests</label>
                <MultiSelect
                  options={sports}
                  value={selectedSports}
                  onChange={setSelectedSports}
                  labelledBy="Select"
                />
            </div>
            <div className="mb-3">
              <label className="form-label">Your Image</label>
              <FileUploadComponent fileUrl={fileUrl} setFileUrl={setFileUrl} fileType="image" fileExtension="image/*" />
            </div>
            <div className="mb-3">
                <label htmlFor="linkedin" className="form-label">Linkedin URL</label>
                <input type="text" className="form-control" id="linkedin" name="linkedin" value={formData.linkedin} onChange={handleChange} disabled={formData.linkedin} />
            </div>
            <div className="mb-3">
                <label htmlFor="twitter" className="form-label">Twitter URL</label>
                <input type="text" className="form-control" id="twitter" name="twitter" value={formData.twitter} onChange={handleChange} disabled={formData.twitter} />
            </div>
            <div className="mb-3">
                <label htmlFor="instagram" className="form-label">Instagram URL</label>
                <input type="text" className="form-control" id="instagram" name="instagram" value={formData.instagram} onChange={handleChange} disabled={formData.instagram} />
            </div>
            <div className="mb-3">
                <label htmlFor="facebook" className="form-label">Facebook URL</label>
                <input type="text" className="form-control" id="facebook" name="facebook" value={formData.facebook} onChange={handleChange} disabled={formData.facebook} />
            </div>
            <button className="btn btn-primary" onClick={handleSubmit}>Update</button>
            </div>
        </div>
    </>
  );
}

export default UserProfileComponent;
