import { Link } from 'react-router-dom';
import { FaqComponent } from 'components';
import { routes } from 'constants';
import { hustlerFaqs } from 'constants';

function HustlerGroupComponent() {
  return (
    <div>
      <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5">
        <img src="https://t4.ftcdn.net/jpg/05/55/89/53/360_F_555895347_Ef8KZBnAucLAD0BL7lOdd9EbUroSl52Y.jpg" className="App-logo d-none" alt="logo" />
        <div className='container text-center'>
          <h4 className='mt-4'>Are you a side hustler?</h4>
          <code className='fs-20 mt-3'>
             Then, welcome to our exclusive PairUpPal Hustler group. Bring ideas, match pairups & organise events to get up to 50% of revenue.
          </code>
        </div>
        <Link
          className="btn btn-turquoise fw-500 px-3 mt-4"
          to={routes.PRICING}
        >
          Join Hustler Group
        </Link>
        <FaqComponent faqs={hustlerFaqs} />
      </div>
    </div>
  );
}

export default HustlerGroupComponent;