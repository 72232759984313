import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaUsers, FaHandshake, FaComments, FaUserFriends, FaHeart } from 'react-icons/fa';

const WhyUseSection = () => {
  return (
    <section className="container-fluid why-use-pairuppal">
      <Container>
        <h2 className="text-center mb-4">Why Use PairUpPal?</h2>
        <Row className="justify-content-center">
          <Col md={6} lg={4}>
            <div className="feature text-center">
              <FaUsers className="feature-icon" />
              <h3 className="feature-title">Discover Exciting Adventures</h3>
              <p className="feature-description">Explore thrilling activities and hobbies with like-minded individuals who share your passion.</p>
            </div>
          </Col>
          <Col md={6} lg={4}>
            <div className="feature text-center">
              <FaHeart className="feature-icon" />
              <h3 className="feature-title">Connect & Thrive</h3>
              <p className="feature-description">Forge meaningful connections, stay active, and reignite your zest for life in a vibrant community.</p>
            </div>
          </Col>
          <Col md={6} lg={4}>
            <div className="feature text-center">
              <FaComments className="feature-icon" />
              <h3 className="feature-title">Engage in Vibrant Conversations</h3>
              <p className="feature-description">Dive into lively discussions, share experiences, and exchange ideas with individuals.</p>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center d-none">
          <Col md={6} lg={4}>
            <div className="feature text-center">
              <FaUserFriends className="feature-icon" />
              <h3 className="feature-title">Find Activities & Hobbies</h3>
              <p className="feature-description">Discover pairups for your favorite activities and hobbies, from fitness routines to creative pursuits.</p>
            </div>
          </Col>
          <Col md={6} lg={4}>
            <div className="feature text-center">
              <FaHeart className="feature-icon" />
              <h3 className="feature-title">Combat Loneliness</h3>
              <p className="feature-description">Join a friendly and collaborative space where individuals socialize, exercise, and pursue shared interests, fostering connections and combating loneliness.</p>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center d-none">
          <Col md={4}>
            <div className="feature">
              <FaUsers className="feature-icon" />
              <h3 className="feature-title">Connect with Like-minded People</h3>
              <p className="feature-description">PairUpPal helps you find and connect with people who share your interests and hobbies.</p>
            </div>
          </Col>
          <Col md={4}>
            <div className="feature">
              <FaHandshake className="feature-icon" />
              <h3 className="feature-title">Build Meaningful Relationships</h3>
              <p className="feature-description">Form genuine connections with others, whether it's for friendship, networking, or collaboration.</p>
            </div>
          </Col>
          <Col md={4}>
            <div className="feature">
              <FaComments className="feature-icon" />
              <h3 className="feature-title">Engage in Conversations</h3>
              <p className="feature-description">Start conversations, share experiences, and exchange ideas with a diverse community of people.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WhyUseSection;
