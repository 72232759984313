import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { toast } from 'helpers';
import { signin } from 'firebaseConfig';
import { routes } from 'constants';

function LoginUserComponent() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    // Perform form validation
    if (formData.email && formData.password) {
      // Form is valid, save to Firestore
      const signinRes = await signin({
        email: formData.email,
        password: formData.password
      });
      if(signinRes.status === 200) {
        navigate(routes.VIEW_PAIRUPS);
      } else {
        toast.error('Something is wrong. Type your email & password again!')
      }
    } else {
      toast.warn('Please fill in all fields');
    }
  };

  return (
    <>
      <div className='d-flex flex-column justify-content-center align-items-center mt-5 py-5 why-use-pairuppal'>
        <h4 className=''>Login</h4>
        <p className='text-center mb-4'>
        Welcome to PairUpPal - Where trust unlocks a world of meaningful connections.
        </p>
        <div className="mb-3 col-9 col-md-3">
            <label htmlFor="email" className="form-label">Email *</label>
            <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} required placeholder='hello@gmail.com' />
        </div>
        <div className="mb-3 col-9 col-md-3">
            <label htmlFor="password" className="form-label">Password *</label>
            <input type="password" className="form-control" id="password" name="password" value={formData.password} onChange={handleChange} required minLength="6" placeholder='*****' />
        </div>
        <button className="btn btn-primary btn-sm col-9 col-md-3" onClick={handleSubmit}>Login</button> <br />
        <Link to={routes.SIGN_UP} className='no-decoration'>New Pal? Signup Here</Link>
      </div>
    </>
  );
}

export default LoginUserComponent;
