import React from 'react';
import { FaMapMarkerAlt, FaCalendarAlt } from 'react-icons/fa';

import { events } from 'constants';
import { formatDateTime } from 'helpers';
import { Link } from 'react-router-dom';
import { routes } from 'constants';

const EventCards = () => {
  return (
    <>
    <div className="container mt-5 pt-5">
    <div className="card text-center d-none">
      <div className="card-header">
        Featured
      </div>
      <div className="card-body">
        <h5 className="card-title">Go Premium</h5>
        <p className="card-text">Become an elite PairUp Pal and attend all our events for 50% off.</p>
        <Link to={routes.PRICING} className="btn btn-primary btn-sm">Join Now</Link>
      </div>
      <div className="card-footer text-muted">
        Limited spots available
      </div>
    </div>
      <div className="row row-cols-1">
        {events.map((event, index) => (
          <div key={index} className="card h-100 my-2">
            <div className="row g-0">
              <div className="col-md-4 col-sm-12">
                <img src={event.image} className="img-fluid rounded-start" alt={event.title} />
              </div>
              <div className="col-md-8 col-sm-12">
                <div className="card-body">
                  <h5 className="">{event.title}</h5>
                  <div className="">{event.description}</div>
                  <div className="text-secondary my-1"><FaMapMarkerAlt className='event-icon' /> {event.location}</div>
                  <div className="text-secondary my-1"><FaCalendarAlt /> {formatDateTime(event.startDateTime)}</div>
                  <a href={event.eventURL} className="btn btn-outline-primary btn-sm mt-2">Join event</a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    </>
  );
};

export default EventCards;
