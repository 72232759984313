import React, { useEffect, useState } from 'react';

import { tableNames } from 'constants';
import { getData } from 'firebaseConfig';
import PairupDetailsComponent from '../details';

const ViewAllPairupsComponent = () => {
  const [pairUps, setPairUps] = useState([]);

  useEffect(() => {
    const fetchPairUps = async () => {
      const res = await getData(tableNames.PAIRUP);
      setPairUps(res);  
    };

    fetchPairUps();
  }, []);

  return (
    <>
    <div className="container my-5 pt-5">
      <h4 className='text-center'>Explore All Pairups</h4>
      <div className='text-center'>Discover a world of shared passions and endless possibilities!</div>

      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 mt-3 align-items-center">
        {pairUps.map((pairup, index) => (
          <div key={index} className="col">
            <PairupDetailsComponent pairup={pairup} />
          </div>
        ))}
      </div>
    </div>
    </>
  );
};

export default ViewAllPairupsComponent;
