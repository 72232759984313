import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md={4}>
            <h4>Important Links</h4>
            <ul className="footer-links">
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/contact">Contact Us</Link></li>
              <li><Link to="/terms">Terms of Service</Link></li>
              <li><Link to="/privacy">Privacy Policy</Link></li>
            </ul>
          </Col>
          <Col md={4}>
            <h4>Follow Us</h4>
            <ul className="social-links">
              <li><a href="https://www.whatsapp.com/channel/0029VaVJfWj0AgWAj49YY23g" target='_blank' rel="noopener noreferrer">Join our Whatsapp</a></li>
            </ul>
          </Col>
          <Col md={4}>
            <h4>Contact Us</h4>
            <p>hello@pairuppal.com</p>
            <p>
            292-294 Plashet Grove, London, E6 1DQ
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
