export const compareTime = (date1, condition = "gt", date2) => {
    // Convert both dates to milliseconds
    const date1Ms = new Date(date1).getTime();
    const date2Ms = new Date(date2).getTime();

    if (condition === "gt") {
        return date1Ms > date2Ms;
    } else {
        return date1Ms < date2Ms;
    }
};