import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { tableNames, routes } from 'constants';
import { getIdData } from 'firebaseConfig';
import { FaArrowLeft } from 'react-icons/fa';
import PalDetailsComponent from '../details';

const SingleView = () => {
  const { palId } = useParams();
  const { dbUser: currentUser } = useSelector(state => state.authUser);
  const [pal, setPal] = useState();

  useEffect(() => {
    const fetchPals = async () => {
      const palRes = await getIdData(tableNames.USER, palId);
      setPal({
        ...palRes,
        id: palId
      });  
    };

    fetchPals();
  }, [palId]);

  return (
    <>
    <div className="container mt-5 pt-5">
      <div className="row justify-content-center mt-3">
        {pal && pal.name ? (
          <div className="col-sm-12 col-md-6">
            <Link className='btn btn-link text-decoration-none' to={routes.VIEW_PALS}>
              <FaArrowLeft /> &nbsp;
              Go back
            </Link>
            <PalDetailsComponent pal={pal} showFullDetails={true} currentUser={currentUser} />
          </div>
        ) : (
          <div className='text-center'>
            <h3>Pal is not available.</h3>
            <Link className='btn btn-link text-decoration-none' to={routes.VIEW_PALS}>
              <FaArrowLeft /> &nbsp;
              Go back
            </Link>
          </div>
        )}
      </div>
    </div>
    </>
  );
};

export default SingleView;
