import React from 'react';

const WhyUniqueSection = () => {
  return (
    <div className="container py-5">
      <h2 className="text-center mb-4">Why PairUpPal is Unique</h2>
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <ul className="list-unstyled">
            <li className="mb-3">
              <strong>Diverse Community</strong><br /> Whether you're a seasoned professional, an aspiring entrepreneur, or simply someone looking to explore new hobbies, PairUpPal welcomes individuals of all backgrounds and interests. This diversity enriches the platform, providing users with a wide range of perspectives and opportunities for growth.
            </li>
            <li>
              <strong>Influencer Engagement</strong><br /> We offer you an opportunity to engage with influencers, industry experts, and thought leaders in their respective fields. Whether it's through exclusive pair ups, or one-on-one interactions, we enables you to connect with influencers and gain valuable insights and inspiration.
            </li>
            <li className="mb-3">
              <strong>Focus on Mental Health</strong><br /> We prioritize the well-being of you by integrating mental health support into its platform. Through features such as access to mental health resources, AI-powered support, and community-driven wellness initiatives, we aim to create a supportive environment where you feel valued, heard, and empowered to prioritize mental health.
            </li>
            <li className="mb-3">
              <strong>Customized Pairing</strong> <br />We offer personalized pairing options that allows to find individuals with similar hobbies, daily activities and goals. This personalized approach ensures that you connect with like-minded individuals only, leading to more fulfilling interactions and collaborations.
            </li>
            <li className="mb-3">
              <strong>Advanced Networking Tools</strong><br /> We empower you to network more effectively and efficiently using AI technology. These tools helps you to discover relevant opportunities, forge meaningful connections, and achieve personal and professional goals.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default WhyUniqueSection;
