import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { toast } from 'helpers';
import { tableNames } from 'constants';
import { addData, signup } from 'firebaseConfig';
import { routes } from 'constants';

function CreateUserComponent() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    gender: '',
    pronouns: '',
    dob: '',
    password: '',
    cpassword: '',
    linkedin: '',
    instagram: '',
    twitter: '',
    facebook: '',
    other: '',
    socialMedia: [
      {type: "linkedin", url: ''},
      {type: "twitter", url: ''},
      {type: "facebook", url: ''},
      {type: "instagram", url: ''},
      {type: "other", url: ''}
    ]
  });
  const today = new Date();
  const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
  const formattedDate = eighteenYearsAgo.toISOString().split('T')[0];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    if (!formData.linkedin && !formData.twitter && !formData.facebook && !formData.instagram && !formData.other) {
      toast.warn("Atleast 1 social media profile link required");
      return;
    }
    // Perform form validation
    if (formData.name && formData.email && formData.pronouns && formData.dob && formData.password && formData.password === formData.cpassword) {
      const signupRes = await signup({
        email: formData.email,
        password: formData.password,
        displayName: formData.name
      });
      if (signupRes.status === 200) {
        // Form is valid, save to Firestore
        const res = await addData(tableNames.USER, {
          ...formData,
          socialMedia: [
            {type: "linkedin", url: formData.linkedin},
            {type: "twitter", url: formData.twitter},
            {type: "facebook", url: formData.facebook},
            {type: "instagram", url: formData.instagram},
            {type: "other", url: formData.other}
          ]
        });
        if (res) {
          toast.success('Your account is activated!');
          navigate(routes.VIEW_PAIRUPS);
        } else {
          toast.error('Something went wrong. Try again later!')
        }
      } else {
        toast.error(signupRes.status === 422 ? "User already exists" : "Something went wrong. Try again later!");
      }
    } else {
      toast.warn('Please fill in all fields');
    }
  };

  return (
    <>
      <div className='d-flex flex-column justify-content-center align-items-center mt-5 pt-5 why-use-pairuppal'>
        <h4 className=''>Signup</h4>
        <p className='text-center mb-4'>
        Join PairUpPal - Where every sign-up sparks the beginning of something new.
        </p>
        <div className='text-black'>
        <div className="mb-3">
            <label htmlFor="name" className="form-label">Name *</label>
            <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} required />
        </div>
        <div className="mb-3 d-none">
            <label htmlFor="gender" className="form-label">Gender *</label>
            <select className="form-select" id="gender" name="gender" value={formData.gender} onChange={handleChange} required>
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            </select>
        </div>
        <div className="mb-3">
            <label htmlFor="pronouns" className="form-label">Pronouns *</label>
            <select className="form-select" id="pronouns" name="pronouns" value={formData.pronouns} onChange={handleChange} required>
            <option value="">Select Pronouns</option>
            <option value="he/him">He/Him</option>
            <option value="she/her">She/Her</option>
            <option value="other">Other</option>
            </select>
        </div>
        <div className="mb-3">
            <label htmlFor="dob" className="form-label">Date of Birth *</label>
            <input type="date" className="form-control" id="dob" name="dob" value={formData.dob} onChange={handleChange} required mindate={formattedDate} />
        </div>
        <div className="mb-3 d-none">
            <label htmlFor="age" className="form-label">Age</label>
            <select className="form-select" id="age" name="age" value={formData.age} onChange={handleChange} required>
            <option value="">Select Age</option>
            {Array.from({ length: 82 }, (_, i) => i + 18).map(age => (
                <option key={age} value={age}>{age}</option>
            ))}
            </select>
        </div>
        <div className="mb-3">
            <label htmlFor="email" className="form-label">Email *</label>
            <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} required />
        </div>
        <div className="mb-3">
            <label htmlFor="password" className="form-label">Password *</label>
            <input type="password" className="form-control" id="password" name="password" value={formData.password} onChange={handleChange} required minLength="10" />
            {formData.password?.length < 10 && <span className='text-danger fs-12'>Password must have at least 10 chars</span>}
        </div>
        <div className="mb-3">
            <label htmlFor="cpassword" className="form-label">Confirm Password *</label>
            <input type="password" className="form-control" id="cpassword" name="cpassword" value={formData.cpassword} onChange={handleChange} required minLength="10" />
        </div>
        <hr />
        <h5 className='my-4'>Atleast 1 social media profile required</h5>
        <div className="mb-3">
            <label htmlFor="linkedin" className="form-label">Linkedin URL</label>
            <input type="text" className="form-control" id="linkedin" name="linkedin" value={formData.linkedin} onChange={handleChange} required />
        </div>
        <div className="mb-3">
            <label htmlFor="twitter" className="form-label">Twitter URL</label>
            <input type="text" className="form-control" id="twitter" name="twitter" value={formData.twitter} onChange={handleChange} required />
        </div>
        <div className="mb-3">
            <label htmlFor="instagram" className="form-label">Instagram URL</label>
            <input type="text" className="form-control" id="instagram" name="instagram" value={formData.instagram} onChange={handleChange} required />
        </div>
        <div className="mb-3">
            <label htmlFor="facebook" className="form-label">Facebook URL</label>
            <input type="text" className="form-control" id="facebook" name="facebook" value={formData.facebook} onChange={handleChange} required />
        </div>
        <div className="mb-3">
            <label htmlFor="other" className="form-label">Any Social Media URL</label>
            <input type="text" className="form-control" id="other" name="other" value={formData.other} onChange={handleChange} required />
        </div>
        <button className="btn btn-primary btn-sm col-12 my-2" onClick={handleSubmit}>Join</button>
        <Link to={routes.LOGIN} className='no-decoration'>Already a Pal? Login Here</Link>
        </div>
      </div>
    </>
  );
}

export default CreateUserComponent;
