import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from "react-redux";

// Import your components
import { HomeComponent, UserProfileComponent, ViewPairupsComponent, PricingComponent, DefaultLayout, ViewEventsComponent, HustlerGroupComponent, ViewPalsComponent, CreateUserComponent, LoginUserComponent, AuthLayout, CreatePairupsComponent, SettingsComponent, PalsSingleViewComponent, PairupsSingleViewComponent, ViewUserPairUpsComponent, RequestPairupComponent, ChatsComponent } from 'components';
import { routes } from 'constants';
import PrivateRoute from './privateRoute';

function RoutesIndex() {
  const { loggedIn, dbUser } = useSelector(state => state.authUser);

  return (
    <Routes>
      <Route element={loggedIn ? <AuthLayout /> : <DefaultLayout />}>
        <Route index element={ <HomeComponent /> } />
        <Route path={routes.SIGN_UP} element={ <CreateUserComponent /> } />
        <Route path={routes.LOGIN} element={ <LoginUserComponent /> } />
        <Route path={routes.JOIN} element={ <UserProfileComponent /> } />
        <Route path={routes.VIEW_PAIRUPS} element={ <ViewPairupsComponent /> } />
        <Route path={routes.VIEW_PALS} element={ <ViewPalsComponent /> } />
        <Route path={routes.VIEW_EVENTS} element={ <ViewEventsComponent /> } />
        <Route path={routes.PRICING} element={ <PricingComponent /> } />
        <Route path={`${routes.VIEW_PAIRUPS}/:pairUpId`} element={ <PairupsSingleViewComponent /> } />
        <Route element={<PrivateRoute currentUser = {dbUser} loggedIn = {loggedIn}  />}>
          <Route path={routes.HUSTLER_GROUP} element={<HustlerGroupComponent />} />
          <Route path={routes.CREATE_PAIRUP} element={<CreatePairupsComponent />} />
          <Route path={`${routes.VIEW_PALS}/:palId`} element={ <PalsSingleViewComponent /> } />
          <Route path={`${routes.CREATE_PAIRUP}/:palId`} element={ <RequestPairupComponent /> } />
          <Route path={routes.CHATS} element={<ChatsComponent />} />
          <Route path={routes.VIEW_USER_PAIRUPS} element={<ViewUserPairUpsComponent />} />
          <Route path={routes.SETTINGS} element={<SettingsComponent />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default RoutesIndex;


// {loggedIn ? (
//   <DefaultLayout>
//   </DefaultLayout>
// ) : (
//   <AuthLayout>
//   </AuthLayout>
// )}
