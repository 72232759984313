export const events = [
  {
    "id": 1,
    "title": "Yoga Session",
    "startDateTime": "2024-04-10T09:00:00+01:00",
    "endDateTime": "2024-04-10T10:00:00+01:00",
    "location": "Community Center",
    "description": "Join us for a rejuvenating yoga session to start your day on a peaceful note.",
    "image": "https://media.istockphoto.com/id/1402927176/photo/group-meditation-class.jpg?s=612x612&w=0&k=20&c=dpP6Ou1OdyUdU9Wc_sa2Va7iLTdVotDLBZXzPlX-Spk="
  },
  {
    "id": 2,
    "title": "Cooking Class: Italian Cuisine",
    "startDateTime": "2024-04-12T18:00:00+01:00",
    "endDateTime": "2024-04-12T20:00:00+01:00",
    "location": "Chef's Kitchen",
    "description": "Learn the art of preparing delicious Italian dishes from our expert chefs.",
    "image": "https://www.ravelloholidayhouse.it/wp-content/uploads/2020/09/amalfi_coast_cooking_class_ravello-768x512.jpg"
  },
  {
    "id": 3,
    "title": "Hiking Adventure",
    "startDateTime": "2024-04-15T10:00:00+01:00",
    "endDateTime": "2024-04-15T14:00:00+01:00",
    "location": "Mountain Trail",
    "description": "Embark on an exciting hiking adventure and explore the beauty of nature.",
    "image": "https://hips.hearstapps.com/hmg-prod/images/hiking-quotes-1586278882.jpg"
  }
]
