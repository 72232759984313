import React from 'react';
import { pricingOptions } from 'constants';

const PricingComponent = () => {
  return (
    <>
    <div className="container py-5 mt-5">
      <h1 className="text-center mb-5">Choose Your Membership Plan</h1>
      <div className="row gx-5 justify-content-center">
        {pricingOptions.map((option, index) => (
          <div key={index} className="col-md-4 my-2">
            <div className="card shadow-sm">
              <div className="card-body">
                <h5 className="card-title text-center mb-4">{option.title}</h5>
                <h6 className="card-subtitle text-center text-muted mb-4">{option.price}</h6>
                <p className="card-title text-center mb-4">{option.description}</p>
                {option.features.map((advantage, idx) => (
                  <p key={idx} className="card-text text-center">{advantage}</p>
                ))}
                {option.discount && (
                  <p className="card-text text-center mt-4">
                    <small className="text-muted">{option.discount}</small>
                  </p>
                )}
                {option.note && (
                  <div className="text-center mt-4">
                    <small className="text-muted">{option.note}</small>
                  </div>
                )}
                <div className="text-center mt-3">
                  <button className={`btn btn-primary ${option.buttonText === "Coming Soon" && "disabled"}`}>
                    {option.buttonText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    </>
  );
};

export default PricingComponent;
