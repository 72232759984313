import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'constants';
import { FaTimes } from 'react-icons/fa';

function StaticHeader() {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  return (
    <nav className="navbar navbar-expand-lg bg-white fixed-top navbar-custom">
      <div className="container">
        {/* Brand */}
        <Link className="navbar-brand" to="/">PairupPal</Link>
        
        {/* Navbar toggler for small screens */}
        <button className="navbar-toggler no-border" type="button" onClick={e => setIsNavbarOpen(!isNavbarOpen)}>
          {isNavbarOpen ? <FaTimes /> : <span className="navbar-toggler-icon"></span>}
        </button>

        
        {/* Navbar items */}
        <div className={`collapse navbar-collapse justify-content-between ${isNavbarOpen ? 'show' : 'hide'}`} id="navbarNav">
          <ul className="navbar-nav" onClick={e => setIsNavbarOpen(false)}>
            <li className="nav-item px-3 py-2">
              <Link className="nav-link" to={routes.VIEW_PAIRUPS}>Pairups</Link>
            </li>
            <li className="nav-item px-3 py-2">
              <Link className="nav-link" to={routes.LOGIN}>Pals</Link>
            </li>
            <li className="nav-item px-3 py-2 d-none">
              <Link className="nav-link" to={routes.PRICING}>Pricing</Link>
            </li>
            <li className="nav-item px-3 py-2 d-none">
              <Link className="nav-link" to={routes.VIEW_EVENTS}>Events</Link>
            </li>
          </ul>
          
          {/* Login button */}
          <ul className="navbar-nav ms-auto" onClick={e => setIsNavbarOpen(false)}>
            <li className="nav-item px-3 py-2 d-none">
              <Link className="nav-link" to={routes.HUSTLER_GROUP}>Hustlers Group</Link>
            </li>
            <li className="nav-item px-3 py-2">
              <Link className="btn btn-outline-primary btn-sm mt-1" to={routes.LOGIN}>Login</Link>
            </li>
            <li className="nav-item px-3 py-2">
              <Link className="btn btn-danger btn-sm mt-1" to={routes.SIGN_UP}>Sign Up</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default StaticHeader;
