import React from 'react';
import UserProfileComponent from 'components/userProfile';

const SettingsComponent = () => {
  return (
    <>
    <div className="container py-5 mt-5">
        <ul className="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
                <div className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Your Profile</div>
            </li>
            <li className="nav-item" role="presentation">
                <div className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Payments</div>
            </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <UserProfileComponent />
            </div>
            <div className="tab-pane fade text-center" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            Become one of our elite PairUp Pal and unlock secret access to our platform. <br />
            <div className='btn btn-secondary btn-sm mt-4 disabled'>Coming Soon</div>
            </div>
        </div>
    </div>
    </>
  );
};

export default SettingsComponent;
