import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTopComponent() {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top of the page when the location changes
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

export default ScrollToTopComponent;
