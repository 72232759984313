export const routes = {
    HOME: "/",
    JOIN: "/join",
    SIGN_UP: "/sign-up",
    LOGIN: "/login",
    CREATE_PAIRUP: "/create-pairup",
    VIEW_PAIRUPS: "/pairups",
    VIEW_USER_PAIRUPS: "/my-pairups",
    CREATE_EVENT: "/create-event",
    VIEW_EVENTS: "/events",
    CREATE_PALS: "/create-pal",
    VIEW_PALS: "/pals",
    HUSTLER_GROUP: "/hustler-group",
    PRICING: "/pricing",
    ABOUT_US: "/about-us",
    CAREERS: "/careers",
    PRIVACY_POLICY: "/privacy-policy",
    TERMS_AND_CONDITIONS: "/terms",
    FAQ: "/faq",
    SETTINGS: "/settings",
    CHATS: "/chats",
}