export const formatDateTime = (dateTimeString) => {
    console.log("cmng here");
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short'
    };
    return new Date(dateTimeString).toLocaleString('en-UK', options);
  };