import { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useDispatch } from "react-redux";
import Routes from "./routes";

import './index.css';
import './App.css';
import { ToastContainer } from "react-toastify";
import { auth, getQuery, signout, timestamp, updateData } from "firebaseConfig";
import { tableNames } from "constants";
import { where } from "firebase/firestore";
import { SetDbUser, SetLoggedIn, SetUser } from "store/actions";
import { LoadingComponent, ScrollToTopComponent } from "components";

function App() {
  const dispatch = useDispatch();
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    function unsubscribe() {
      auth.onAuthStateChanged(async (user) => {
        const isAuthenticated = user != null;
        if (isAuthenticated) {
          dispatch(SetUser(user.toJSON()));
          dispatch(SetLoggedIn(true));
          user.getIdTokenResult().then(async (idTokenResult) => {
            const token = idTokenResult.token;
            const tokenExpirationTime = new Date(
              idTokenResult.expirationTime
            ).getTime();
            const currentTime = Date.now();
            if (tokenExpirationTime < currentTime) {
              /* Token is expired */
              await signout();
              window.location.reload();
            } else {
              window.sessionStorage.setItem("token", token);
              window.sessionStorage.setItem(
                "tokenExpirationTime",
                tokenExpirationTime
              );
              /* Get user details */
              const currentUser = user.toJSON();
              const users = await getQuery(
                tableNames.USER, where("email", "==", currentUser.email)
              );
              // Do it asynchronously. No need to await
              updateData(tableNames.USER, users[0].id, { lastSigninTime: timestamp, accessToken: token });
              dispatch(SetDbUser(users[0]));
              setLoading(false);
            }
          })
        } else {
          setLoading(false);
        }
      }); 
    }
    unsubscribe();
  }, [setLoading, dispatch]);

  return (
    <>
      <ToastContainer />
      {
        loading ? <LoadingComponent /> : (
          <Router>
            <ScrollToTopComponent />
            <Routes />
          </Router>
        )
      }
    </>
  );
}

export default App;
