import React, { useState } from 'react';
import { uploadFile } from 'firebaseConfig';

import {toast} from "helpers";

const FileUploadComponent = ({
    fileType,
    fileExtension,
    fileUrl,
    setFileUrl
}) => {
  const [uploading, setUploading] = useState(false);

  const handleFileUpload = async (e) => {
    let file = e.target.files[0];
  
    if (!file) {
      toast.error("New image required");
      return null;
    }
    setUploading(true);
    await uploadFile(file, fileType, async (url, err) => {
      if (err) {
        alert(err);
        return null;
      }
    //   event.target.value = null;
      setFileUrl(url);
      setUploading(false);
    });
  };

  return (
    <div>
        {uploading && <div class="spinner-border" role="status"></div>}
        {!uploading && fileUrl && fileType === "image" && <img src={fileUrl} alt='User Profile' className='preview-image' />}
        <input type="file" className='d-none' id="customFile1" onChange={handleFileUpload} accept={fileExtension} />
        <label className='form-label btn btn-outline-primary btn-sm col-12' for="customFile1">Upload</label>
    </div>
  );
};

export default FileUploadComponent;
