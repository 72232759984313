import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { getFirestore, collection, addDoc, connectFirestoreEmulator, getDocs, query, updateDoc, serverTimestamp, doc, getDoc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

// TODO: Replace the following with your app's Firebase project configuration
let config = {};
if (process.env.REACT_APP_ENV === "development") {
  config = {
    apiKey: process.env.REACT_APP_DEV_API_KEY,
    authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
    projectId: process.env.REACT_APP_DEV_PROJECT_ID,
    storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_DEV_ID,
  };
}

if (process.env.REACT_APP_ENV === "production") {
  config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };
}

export const app = initializeApp(config);
export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);
export const timestamp = serverTimestamp();

if (
  process.env.REACT_APP_ENV === "local" ||
  window.location.hostname === "localhost"
) {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectFirestoreEmulator(firestore, "localhost", 8080);
}

export const signup = ({ email, password, data }) => {
  return createUserWithEmailAndPassword(auth, email, password)
    .then(res => {
      return formatResult(200, "User created successfully", res.user.providerData[0]);
    })
    .catch(error => formatResult(422, error.message));
}

export const signin = ({ email, password }) => {
  return signInWithEmailAndPassword(auth, email, password)
    .then(res => {
      return formatResult(200, "User loggedin successfully", res.user.providerData[0]);
    })
    .catch(error => formatResult(422, error.message));
}

export const signout = () => {
  return signOut(auth).then(() => {
    window.sessionStorage.setItem("token", "");
    window.sessionStorage.setItem("tokenExpirationTime", 0);
    // window.lo/
  });
};

// Add data to Firestore
export const addData = async (tableName, data) => {
    data.createdAt = new Date().getTime();
    data.updatedAt = new Date().getTime();

    return new Promise((resolve, reject) => {
        addDoc(collection(firestore, tableName), data)
          .then(docRef => {
            resolve(docRef.id);
          })
          .catch(error => {
            reject('Error adding document: ' + error);
          });
    });
};

// Add data to Firestore
export const updateData = async (tableName, id, data) => {
  data.updatedAt = new Date().getTime();
  const docRef = doc(firestore, tableName, id);

  return updateDoc(docRef, data)
    .then(res => formatResult(200, "Successfully updated", res))
    .catch((e) => formatResult(422, e.message));
};

export const getIdData = (tableName, id) => {
  const docRef = doc(firestore, tableName, id);
  return getDoc(docRef)
    .then((doc) =>
      doc.exists() ? doc.data() : formatResult(404, "No data found")
    )
    .catch((err) => formatResult(err.code, err.message));
};

export const getData = async (tableName, id = "all") => {
  const _collection = collection(firestore, tableName);
  // const snapshot = await getDocs(_collection);

  return getDocs(_collection)
    .then((results) => {
      const object = [];
      results.forEach((doc) => {
        object.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      return object.sort((a, b) => b.createdAt - a.createdAt);
    })
    .catch((err) => {
      console.error(err);
      return { error: err.code };
    });
};

export const getQuery = (tableName, whereQuery) => {
  const q = query(collection(firestore, tableName), whereQuery);
  return getDocs(q)
    .then((results) => {
      const object = [];
      results.forEach((doc) => {
        object.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      return object.sort((a, b) => a.updatedAt - b.updatedAt);
    })
    .catch((err) => {
      return { error: err.code };
    });
};

export const removeData = (tableName, id) => {
  const docRef = doc(firestore, tableName, id);

  return deleteDoc(docRef)
    .then((suc) => formatResult(200, "Deleted successfully"))
    .catch((er) => formatResult(500, er.message));
};

// Upload file
export const uploadFile = (file, type, callback) => {
  const storage = getStorage();
  const fileName = file.name + "_" + Date.now();
  let fileRef = ref(storage, `app/avatars/${fileName}`);

  if (type === "audio") {
    fileRef = ref(storage, `app/audios/${fileName}`);
  }
  if (type === "image") {
    fileRef = ref(storage, `app/images/${fileName}`);
  }
  const metadata = {
    contentType: file.type,
    contentSize: file.size,
  };
  const uploadTask = uploadBytesResumable(fileRef, file, metadata);
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = Math.floor(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      // setBtnUpload(progress + "%");
      console.log(progress + "%");
      switch (snapshot.state) {
        case "paused":
          console.log("Upload is paused");
          break;
        case "running":
          console.log("Upload is running");
          break;
        default:
          break;
      }
    },
    (err) => {
      switch (err.code) {
        case "storage/unauthorized":
          err.message = "Unauthorized user";
          break;
        case "storage/cancelled":
          err.message = "Upload cancelled";
          break;
        case "storage/unknown":
          err.message = "Unknown error occured";
          console.log("unknown error occured, inspect err.serverResponse");
          break;
        default:
          break;
      }
      // callback('', new Error(err))
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref)
        .then((fileUrl) => callback(fileUrl));
    }
  );
};

/* Common code */
function formatResult(status, message, data = {}) {
  return { status, message, data };
}
