import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { toast } from 'helpers';
import { tableNames, routes, pairupTypes } from 'constants';
import { addData } from 'firebaseConfig';
import { useSelector } from 'react-redux';

function CreatePairupsComponent() {
  const { dbUser: currentUser } = useSelector(state => state.authUser);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    address: '',
    zipcode: '',
    startTime: '',
    endTime: '',
    cost: '',
    type: pairupTypes[0],
    attendance: 1,
    participantIds: [],
    requestedParticipantIds: []
  });
  const [selectedOption, setSelectedOption] = useState(pairupTypes[0]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    const selectedObject = pairupTypes.find(option => option.key === selectedValue);
    setSelectedOption(selectedObject);
  };

  const handleSubmit = async (e) => {
    // Perform form validation
    if (formData.name && formData.address && formData.zipcode && formData.startTime && formData.endTime) {
      const res = await addData(tableNames.PAIRUP, {
        ...formData,
        user: {
          id: currentUser.id,
          name: currentUser.name,
          pronouns: currentUser.pronouns,
          linkedin: currentUser.linkedin,
          photo: currentUser.photo
        }
      });
      if (res) {
        toast.success('Your pairup is live! 🥳');
        navigate(routes.VIEW_PAIRUPS);
      } else {
        toast.error('Something went wrong. Try again later!')
      }
    } else {
      toast.warn('Please fill in all fields');
    }
  };

  return (
    <>
      <div className='container container-body pt-5 mt-5'>
        <h4 className='text-center'>Create Pairup</h4>
        <div className='text-center'>Where every connection begins!</div>

        <div className='container max-w-70'>
          <div className='row'>
            <div className="mb-3 col-12 col-sm-6">
                <label htmlFor="type" className="form-label">Pairup Type</label>
                <select className="form-select" id="type" name="type" onChange={handleSelectChange} required>
                  {pairupTypes.map(put => put.display && <option value={put.key}>{put.value}</option>)}
                </select>
            </div>
            {
              selectedOption.isPaid && (
                <div className="mb-3 col-12 col-sm-6">
                  <label htmlFor="cost" className="form-label">Cost in GBP (£)</label>
                  <input type="number" className="form-control" id="cost" name="cost" value={formData.cost} onChange={handleChange} required placeholder='100' />
                </div>
              )
            }
            <div className='divider'></div>
            <div className="mb-3 col-12 col-sm-6">
                <label htmlFor="name" className="form-label">Pairup For</label>
                <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} required placeholder='Hiking, swimming, walking, dancing, etc.' />
                <div className='d-none'>
                Suggestions: 
                <button className='btn btn-outline-primary btn-sm'>Hiking</button>
                <button className='btn btn-outline-primary btn-sm'>Swimming</button>
                <button className='btn btn-outline-primary btn-sm'>Other</button>
            </div>
            </div>
            <div className="mb-3 col-12 col-sm-6">
                <label htmlFor="description" className="form-label">Description</label>
                <textarea className="form-control" id="description" name="description" value={formData.description} onChange={handleChange} placeholder='Tell more about this pairup..' />
            </div>
            <div className="mb-3 col-12 col-sm-6">
                <label htmlFor="startTime" className="form-label">Start time</label>
                <input type="datetime-local" className="form-control" id="startTime" name="startTime" value={formData.startTime} onChange={handleChange} required placeholder="Date" />
            </div>
            <div className="mb-3 col-12 col-sm-6">
                <label htmlFor="endTime" className="form-label">End time</label>
                <input type="datetime-local" className="form-control" id="endTime" name="endTime" value={formData.endTime} onChange={handleChange} required />
            </div>
            <div className="mb-3">
                <label htmlFor="attendance" className="form-label">No. of pals can attend</label>
                <input type="number" className="form-control" id="attendance" name="attendance" value={formData.attendance} onChange={handleChange} required />
            </div>
            <div className="mb-3 col-12 col-sm-6">
                <label htmlFor="zipcode" className="form-label">Zipcode</label>
                <input type="text" className="form-control" id="zipcode" name="zipcode" value={formData.zipcode} onChange={handleChange} required />
            </div>
            <div className="mb-3 col-12 col-sm-6">
                <label htmlFor="address" className="form-label">Address</label>
                <input type='text' className="form-control" id="address" name="address" value={formData.adress} onChange={handleChange} required placeholder='Complete Address...' />
            </div>
            <div className='divider'></div>
            <div className='d-none'>
              Suggestions: 
              <div className='card col-3'>
                Locatoin Name  (eco-friendly score 2.0
                <button className='btn btn-outline-primary btn-sm'>Location 1)</button>
              </div>
              <div className='card col-3'>
                Locatoin Name
                <button className='btn btn-outline-primary btn-sm'>Location 1</button>
              </div>
            </div>
            <button className="btn btn-primary col-sm-4" onClick={handleSubmit} disabled={selectedOption.disabled}>Create Pairup</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreatePairupsComponent;
