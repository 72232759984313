export const calculateTimeDifference = (date1, date2, outputType="text") => {
    // Convert both dates to milliseconds
    const date1Ms = new Date(date1).getTime();
    const date2Ms = new Date(date2).getTime();

    // Calculate the difference in milliseconds
    let differenceMs = Math.abs(date2Ms - date1Ms);

    // Calculate days, hours, and minutes
    const days = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
    differenceMs -= days * 1000 * 60 * 60 * 24;
    const hours = Math.floor(differenceMs / (1000 * 60 * 60));
    differenceMs -= hours * 1000 * 60 * 60;
    const minutes = Math.floor(differenceMs / (1000 * 60));

    if (outputType === "text") {
        const dayString = days > 0 ? (days + (days === 1 ? " day" : " days")) : "";
        const hourString = hours > 0 ? (hours + (hours === 1 ? " hr" : " hrs")) : "";
        const minuteString = minutes > 0 ? (minutes + (minutes === 1 ? " min" : " mins")) : "";
        return `${dayString} ${hourString} ${minuteString}`;
    } else {
        return { days, hours, minutes };
    }
};