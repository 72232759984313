export const sports = [
    { value: 'badminton', label: 'Badminton' },
    { value: 'bridge', label: 'Bridge' },
    { value: 'climbing', label: 'Climbing' },
    { value: 'cycling', label: 'Cycling' },
    { value: 'dancing', label: 'Dancing' },
    { value: 'fishing', label: 'Fishing' },
    { value: 'golf', label: 'Golf' },
    { value: 'hiking', label: 'Hiking' },
    { value: 'inline_skating', label: 'Inline Skating' },
    { value: 'padel', label: 'Padel' },
    { value: 'running', label: 'Running' },
    { value: 'sailing', label: 'Sailing' },
    { value: 'skating', label: 'Skating' },
    { value: 'skiing', label: 'Skiing' },
    { value: 'squash', label: 'Squash' },
    { value: 'swimming', label: 'Swimming' },
    { value: 'tennis', label: 'Tennis' },
    { value: 'walking', label: 'Walking' },
    { value: 'working_out', label: 'Working Out' },
];