import React, { useState } from 'react';

const FaqComponent = ({ faqs }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div className="accordion col-10 col-sm-8 my-3" id="faqAccordion">
      {faqs.map((faq, index) => (
        <div className="accordion-item" key={index}>
          <h2 className="accordion-header" id={`faqHeading${index}`}>
            <button className={`accordion-button ${activeIndex === index ? '' : 'collapsed'}`} type="button" onClick={() => handleToggle(index)}>
              {faq.question}
            </button>
          </h2>
          <div className={`accordion-collapse collapse ${activeIndex === index ? 'show' : ''}`} aria-labelledby={`faqHeading${index}`} data-bs-parent="#faqAccordion">
            <div className="accordion-body">
              {faq.answer}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FaqComponent;
